exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../../../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-cases-glass-manufacturer-index-js": () => import("./../../../src/pages/cases/glassManufacturer/index.js" /* webpackChunkName: "component---src-pages-cases-glass-manufacturer-index-js" */),
  "component---src-pages-cases-index-js": () => import("./../../../src/pages/cases/index.js" /* webpackChunkName: "component---src-pages-cases-index-js" */),
  "component---src-pages-cases-jingniao-index-js": () => import("./../../../src/pages/cases/jingniao/index.js" /* webpackChunkName: "component---src-pages-cases-jingniao-index-js" */),
  "component---src-pages-cases-shouhang-index-js": () => import("./../../../src/pages/cases/shouhang/index.js" /* webpackChunkName: "component---src-pages-cases-shouhang-index-js" */),
  "component---src-pages-cases-walmart-index-js": () => import("./../../../src/pages/cases/walmart/index.js" /* webpackChunkName: "component---src-pages-cases-walmart-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-index-js": () => import("./../../../src/pages/products/index.js" /* webpackChunkName: "component---src-pages-products-index-js" */),
  "component---src-pages-products-magaogao-index-js": () => import("./../../../src/pages/products/magaogao/index.js" /* webpackChunkName: "component---src-pages-products-magaogao-index-js" */),
  "component---src-pages-products-supply-chain-index-js": () => import("./../../../src/pages/products/supply-chain/index.js" /* webpackChunkName: "component---src-pages-products-supply-chain-index-js" */),
  "component---src-pages-products-zhuangmanman-index-js": () => import("./../../../src/pages/products/zhuangmanman/index.js" /* webpackChunkName: "component---src-pages-products-zhuangmanman-index-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

